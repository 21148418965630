/* eslint-disable canonical/id-match */
/* eslint-disable max-len */
/* eslint-disable unicorn/no-array-reduce */
/* eslint-disable canonical/filename-match-regex */
/* eslint-disable canonical/filename-match-exported */
import {filesActionTypes} from '../store/actions';
import Api from '.';
import {actionCreator,
  Alert,
  unauthorized} from './utils';

class FilesApi extends Api {
  constructor () {
    super('/api/v2/files');
  }

  getFileById ({fileId}, errorCallback) {
    return async (dispatch) => {
      try {
        const {data} = await this.get(`/${fileId}`);
        dispatch(actionCreator(filesActionTypes.GET_FIRM_FILE_BY_ID_SUCCESS, data));
      } catch (error) {
        if (errorCallback) {
          errorCallback();
        }

        unauthorized(error?.response?.status);
        Alert(error?.response?.data?.message);
      }
    };
  }

  async getFileByIdAsync (fileId, dispatch) {
    let data = null;
    try {
      data = await this.get(`/${fileId}`);
    } catch (error) {
      dispatch(actionCreator(filesActionTypes.FILE_BY_ID_LOADING, false));
      unauthorized(error?.response?.status);
      Alert(error?.response?.data?.message);
    }

    return data?.data;
  }
}

const filesV2Api = new FilesApi();
export default filesV2Api;
