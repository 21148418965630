import FilesApi from '../../../../../../api/files-v2';
import { RecentDocument } from './components/RecentDocument';
import { UsualDocument } from './components/UsualDocument';
import { documentsApi } from 'api/documentsApi';
import { actionCreator } from 'api/utils';
import { ModalActivationContext } from 'contexts/ModalActivation.context';
import {
  type FilesStore,
  type IndividualFileStore,
} from 'pages/Firm/FileViewModal/FileViewModal';
import {
  type FC,
  type SyntheticEvent,
  useContext,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { notificationsService } from 'services';
import { filesActionTypes } from 'store/actions';
import { type DocumentsStoreState } from 'store/slices';
import { markDocumentsAsSeen } from 'store/slices';
import {
  type DocumentDetailsFromStorage,
  type DocumentDTO,
  type IndividualFile,
  storeItemsInStorage,
} from 'types';

type DocumentComponentProps = {
  categoryId: string,
  documentObject: DocumentDTO,
};

export const Document: FC<DocumentComponentProps> = ({
  categoryId,
  documentObject,
}) => {
  const dispatch = useDispatch();
  const { toggleFileViewModal } = useContext(ModalActivationContext);

  const {
    fileByIdLoading,
    viewingFileByIdLoading,
  } = useSelector<FilesStore>((state) => state.files) as IndividualFileStore;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { _id,
    userViewed,
    fileType,
    fileName,
    quarter,
    year } = documentObject;

  const groupName = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents.newCategory
      ?.documentTypeGroups.find((group) => group.documentGroupId === fileType)
      ?.documentGroupName,
  ) as string;

  const handleDownload = async (file: IndividualFile) => {
    try {
      const response = await fetch(file.url, { method: 'GET' });

      if (!response.ok) {
        notificationsService.error('Error while downloading: file not found');
        dispatch(actionCreator(filesActionTypes.FILE_BY_ID_LOADING, false));
        return;
      }

      const arrayBuffer = await response.arrayBuffer();

      // Create a new Blob with the desired type (application/pdf)
      const blob = new Blob([
        arrayBuffer,
      ], { type: 'application/pdf' });

      // Create a download link
      const downloadLink = document.createElement('a');
      downloadLink.id = 'a';
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = `${file.fileName}.pdf`;
      // Trigger the download
      downloadLink.click();

      // Clean up the link
      window.URL.revokeObjectURL(downloadLink.href);
      if (!userViewed) {
        await documentsApi.viewDocuments({
          documentIds: [
            _id,
          ],
        });
        dispatch(markDocumentsAsSeen({
          categoryId,
          documentGroupId: fileType,
          documentIds: [
            _id,
          ],
        }));
      }

      dispatch(actionCreator(filesActionTypes.FILE_BY_ID_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(filesActionTypes.FILE_BY_ID_LOADING, false));
      notificationsService.error(`Error downloading file: ${error}`);
    }
  };

  const downloadClick = async (event: SyntheticEvent | undefined) => {
    event?.stopPropagation();

    dispatch(actionCreator(filesActionTypes.FILE_BY_ID_LOADING, true));
    const file =
      await FilesApi.getFileByIdAsync(_id, dispatch) as unknown as IndividualFile;
    if (file?.url) {
      await handleDownload(file);
    }

    return file;
  };

  const toggleDocumentViewedStatus = async (
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
  ) => {
    event?.stopPropagation();
    try {
      dispatch(actionCreator(filesActionTypes.VIEWING_FILE_BY_ID_LOADING, true));
      await documentsApi.viewDocuments({
        documentIds: [
          _id,
        ],
      });

      dispatch(markDocumentsAsSeen({
        categoryId,
        documentGroupId: fileType,
        documentIds: [
          _id,
        ],
      }));
      dispatch(actionCreator(filesActionTypes.VIEWING_FILE_BY_ID_LOADING, false));
    } catch {
      notificationsService.error('Error while viewing');
      dispatch(actionCreator(filesActionTypes.VIEWING_FILE_BY_ID_LOADING, false));
    }
  };

  const documentOnClick = async (
    event: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    if (event instanceof KeyboardEvent && event.key !== 'Tab') {
      return;
    }

    storeItemsInStorage<DocumentDetailsFromStorage>({
      categoryId,
      documentGroupId: fileType,
      documentGroupName: groupName,
      documentId: _id,
      isNewDocument: userViewed ? 'false' : 'true',
    }, sessionStorage);

    toggleFileViewModal(true, _id);
  };

  const parts = fileName.split('_');
  const period = quarter && year ? `${quarter} ${year}` : year || parts[parts.length - 1] || '';

  return (
    <>
      {categoryId === 'New Documents Category' ?
        <RecentDocument
          documentOnClick={documentOnClick}
          downloadClick={downloadClick}
          fileByIdLoading={fileByIdLoading}
          groupName={groupName}
          period={period}
          {
            ...documentObject
          }
        />
        :
        <UsualDocument
          documentOnClick={documentOnClick}
          downloadClick={downloadClick}
          fileByIdLoading={fileByIdLoading}
          period={period}
          toggleDocumentViewedStatus={toggleDocumentViewedStatus}
          viewingFileByIdLoading={viewingFileByIdLoading}
          {
            ...documentObject
          }
        />}
    </>

  );
};
