import ChevronDown from '../../assets/icons/global/chevron-down.svg?react';
import style from './MultiSelector.module.scss';
import MultiSelectorOptionList from './MultiSelectorOptionList/MultiSelectorOptionList';
import { Input } from 'antd';
import {
  type SelectDisplayOption,
  type SelectOption,
} from 'hooks';
import {
  useMultiSelector,
} from 'hooks';
import {
  type CSSProperties,
  type FC,
  type SetStateAction,
} from 'react';
import {
  useEffect,
  useRef,
  useState,
} from 'react';

type MultiSelectorProps = {
  dataSelectionValue?: string,
  disabled: boolean,
  onOption?: (option: string[]) => void,
  optionList: SelectOption[],
  placeholder: string,
  selectedIds: string[],
  style?: CSSProperties,
  title: string,
};

const MultiSelector: FC<MultiSelectorProps> = ({
  optionList,
  placeholder,
  title,
  style: inputStyle,
  onOption,
  selectedIds,
  disabled,
  dataSelectionValue = 'multiselector-container',
}) => {
  const [
    focused,
    setFocused,
  ] = useState(false);
  const componentRef = useRef(
    null,
  ) as unknown as React.MutableRefObject<HTMLDivElement>;
  const { options,
    markOption,
    isAllOption,
    display,
  } = useMultiSelector({
    optionList,
    selectedIds,
    title,
  });

  const toggleOpen = (
    open: SetStateAction<boolean>,
    event: MouseEvent | React.MouseEvent<HTMLDivElement>,
  ) => {
    event.stopPropagation();
    if (open === focused) {
      return;
    }

    setFocused(open);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickOutside = (event: MouseEvent) => {
    if (
      focused &&
      componentRef.current &&
      !componentRef.current.contains(event.target as Node)
    ) {
      setFocused(false);
    }
  };

  useEffect(() => {
    // Add a click event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [
    handleClickOutside,
  ]);

  // eslint-disable-next-line max-len
  const onClickOption = (optionValue: SelectDisplayOption) => (event: React.MouseEvent<HTMLDivElement>) => {
    // event.preventDefault();
    event.stopPropagation();
    const newOptions = markOption(optionValue.id);
    if (onOption) {
      onOption(newOptions.slice(1)
        .filter((option) => option.selected).map((option) => option.id));
    }
  };

  return (
    <div
      className={style.multiSelectorContainer}
      data-test={`${dataSelectionValue}`}
      onClick={(event) => toggleOpen(!focused, event)}
      ref={componentRef}
      style={inputStyle}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      <div
        className='flex cursor-pointer flex-col items-start gap-0.125'
        data-test={`${dataSelectionValue}-selected-value`}
        style={{ cursor: 'pointer' }}
      >
        <div className='text-font-3 font-medium'>
          {title}
        </div>
        <div className={style.selectedValue} >
          <Input
            className={style.inputField}
            data-test={`${dataSelectionValue}-input`}
            disabled={disabled}
            placeholder={placeholder}
            style={{ borderColor: !disabled && focused ? 'rgba(0, 91, 179, 1)' : '' }}
            suffix={!disabled && <ChevronDown />}
            type='text'
            value={display}
          />
        </div>
      </div>
      {options?.length > 1 && <MultiSelectorOptionList
        dataSelectionValue={dataSelectionValue}
        focused={focused}
        isAllOption={isAllOption}
        onClickOption={onClickOption}
        optionList={optionList}
        options={options}
      />}
    </div>
  );
};

export { MultiSelector };
