import { Tooltip } from 'antd';
import { limitString } from 'components/Pipes/textPipes';
import { Svgicon } from 'components/Svgicon/Svgicon';
import { type FC,
  useState } from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { isMobile } from 'services/mobile';
import { type OrganizationsStoreState } from 'store/slices';
import {
  selectOrganization,
} from 'store/slices';
import { type NestedOrganization } from 'types';

export const MAX_NAME_LENGTH = 55;

type OrgColumnProps = {
  showScndPage: (show: boolean) => void,
};

type OrgOptionProps = {
  backArrow?: boolean,
  onClick: (id: string) => void,
  org: NestedOrganization,
  selected: boolean,
};

const OrgOption: FC<OrgOptionProps> = ({ backArrow,
  org,
  selected,
  onClick }) => {
  const orgName = () => <span className='text-font-1 dt:text-font-2'>
    {limitString(org.organizationName, MAX_NAME_LENGTH)}
  </span>;
  return (
    <div
      className={`flex w-full cursor-pointer items-center gap-0.75 rounded-sm
        px-0.5 py-0.75 hover:bg-black-100 ${
    selected ? '!bg-main-700 text-white-999' : ''
    }`}
      data-test={`org-${org.organizationName}`}
      onClick={() => onClick(org.organizationId)}
    >
      {backArrow && <Svgicon className='size-1' id='UP-arrow-left' />}
      <div className='flex size-1.25 items-center justify-center'>
        <img alt='orgLogo' className='max-h-full max-w-full' src={org.organizationLogo} />
      </div>
      { org.organizationName.length > MAX_NAME_LENGTH ?
        <Tooltip title={org.organizationName}>{orgName()}</Tooltip> : orgName()}
    </div>
  );
};

export const OrganizationsColumn: FC<OrgColumnProps> = ({ showScndPage }) => {
  const dispatch = useDispatch();
  const { organizations,
    displayedOrganization } = useSelector<{
    organizations: OrganizationsStoreState,
  }>((state) => state.organizations) as OrganizationsStoreState;
  const [
    mobile,
    setMobile,
  ] = useState(isMobile());
  const [
    showMobileSecondPageHeader,
    setShowMobileSecondPageHeader,
  ] =
    useState(mobile && organizations?.length === 1);

  const changeOrganization = (orgId: string) => {
    dispatch(selectOrganization(orgId));

    showScndPage(true);
    if (isMobile()) {
      setShowMobileSecondPageHeader(true);
    }
  };

  window.onresize = () => {
    setMobile(isMobile());
    setShowMobileSecondPageHeader(mobile && organizations?.length === 1);
    showScndPage(!isMobile());
  };

  if (showMobileSecondPageHeader) {
    return (
      <div className='pr-3'>
        <OrgOption
          backArrow={(organizations?.length || 0) > 1}
          onClick={() => {
            if ((organizations?.length || 0) > 1) {
              setShowMobileSecondPageHeader(false);
              showScndPage(false);
            }
          }}
          org={displayedOrganization as NestedOrganization}
          selected={false}
        />
      </div>
    );
  }

  return (
    <div className='column-width flex flex-col items-start gap-0.75'>
      <span className='my-[0.75rem] px-0.5 text-font-1 font-medium dt:my-0'>Organization</span>
      <div className='flex max-h-[83vh] w-full flex-col overflow-auto'>
        {organizations?.map((org) =>
          <OrgOption
            key={org.organizationId}
            onClick={(organizationId) => {
              changeOrganization(organizationId);
            }}
            org={org}
            selected={
              org.organizationId === displayedOrganization?.organizationId
            }
          />,
        )}
      </div>
    </div>
  );
};
