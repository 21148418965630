import { type CapitalAccountsStoreState } from '../../../../../store/slices';
import {
  selectPeriodForAccount,
} from '../../../../../store/slices';
import { Dropdown } from '../../../../Dropdown';
import style from './CapitalAccountsPeriodSelector.module.scss';
import ChevronSvg from 'assets/icons/global/blue-chevron-right.svg?react';
import { limitString } from 'components/Pipes/textPipes';
import { type SelectOption } from 'hooks';
import { type FC } from 'react';
import {
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { type CapitalAccountAccount } from 'types';

export type CapitalAccountsPeriodSelectorProps = Pick<
CapitalAccountAccount, '_id' | 'legalName'> & {
  closeSignal?: boolean,
  fundId: string,
  periodList: string[],
};

export const CapitalAccountsPeriodSelector: FC<CapitalAccountsPeriodSelectorProps> = ({
  _id,
  closeSignal,
  fundId,
  legalName,
  periodList,
}) => {
  const initialPeriod = useSelector<{
    capitalAccounts: CapitalAccountsStoreState,
  }>(
    (state) => state
      .capitalAccounts
      .selectedFundAccountPeriodMap[fundId][_id],
  ) as string;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const periodOptions = periodList.map((period) => ({
    display: period,
    id: period,
  }));

  const [
    selectedPeriod,
    setSelectedPeriod,
  ] = useState<SelectOption>(
    periodOptions.find((period) => period.id === initialPeriod) || periodOptions[0],
  );

  const onSelectPeriod = (newValue: SelectOption) => {
    dispatch(selectPeriodForAccount({
      accountId: _id,
      fundId,
      period: newValue.id,
    }));

    setSelectedPeriod(newValue);
  };

  const onSelectAccount = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigate(`/firm/capital-accounts/${fundId}/${_id}`);
  };

  return <button
    className={
      `${style.capitalAccountsPeriodSelector}
    flex flex-row items-center justify-between px-1.25`
    }
    onClick={onSelectAccount}
    type='button'
  >
    <div
      className='inline-flex items-center gap-x-1'
      data-test={`account-${legalName}`}
    >
      <p className='m-0 text-title-1 font-normal text-custom-blue hover:underline'>
        {limitString(legalName, 15)}
      </p>
      <ChevronSvg />
    </div>
    <Dropdown
      className={style.dropdown}
      closeSignal={closeSignal}
      dataSelectionValue='ca-fund-selector-dropdown'
      displayBackgroundColor='#D9D9D9'
      displayColor='#4A5056'
      displayFontWeight='500'
      displayHeight='1.25rem'
      displaySize='11px'
      optionList={periodOptions}
      optionsClassName={style.options}
      placeholder='Period'
      setValue={onSelectPeriod}
      value={selectedPeriod}
    />
  </button>;
};
