import {
  CapitalAccountsBanner,
  DocumentCategories,
  ReportsModule,
} from '../../../components';
import { useDocumentTitle } from '../../../hooks';
import {
  type CapitalAccountsStoreState,
  type DocumentsStoreState,
  fetchCapitalAccountsPreview,
  fetchCategories,
  fetchReports,
  type Filters,
  type FilterStoreState,
  type PeriodFilters} from '../../../store/slices';
import style from './Documents.module.scss';
import { type FundForDataRoom } from 'api/dataRoomApi';
import { dataRoomApi } from 'api/dataRoomApi';
import classNames from 'classnames';
import { DataRoom } from 'components/DataRoom/DataRoom';
import { useEffect,
  useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { type AppDispatch } from 'store';

export const Documents = () => {
  const {
    reports,
    newCategory,
    otherCategories,
    categoriesPending,
  } = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents,
  ) as DocumentsStoreState;

  const activeFilters = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters.activeFilters) as Filters | null;

  const periodFilters = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters.usualDocumentsFilter) as PeriodFilters | null;

  const {
    hasCapitalAccounts,
  } = useSelector<{ capitalAccounts: CapitalAccountsStoreState, }>(
    (state) => state.capitalAccounts,
  ) as CapitalAccountsStoreState;
  useDocumentTitle('Investor Portal');
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [
    dataRoomFunds,
    setDataRoomFunds,
  ] = useState<FundForDataRoom[]>([]);

  const fetchDataRoomFunds = async () => {
    if (activeFilters) {
      const res = await dataRoomApi.getDataRoomFunds({
        firmId: activeFilters.organizationId,
        fundIds: activeFilters.fundIds,
      });
      setDataRoomFunds(res.funds);
    }
  };

  useEffect(() => {
    if (!activeFilters) {
      navigate('/firms');
      return;
    }

    dispatch(fetchCategories({
      ...activeFilters,
      firmId: activeFilters.organizationId,
      quarters: periodFilters?.quarters,
    }));

    dispatch(fetchReports({
      firmId: activeFilters.organizationId,
      fundIds: activeFilters.fundIds,
    }));

    dispatch(fetchCapitalAccountsPreview({
      accountIds: activeFilters.accountIds,
      firmId: activeFilters.organizationId,
    }));

    if (activeFilters) {
      fetchDataRoomFunds();
    }
  }, [
    dispatch,
    activeFilters,
    navigate,
  ]);

  return (
    <div className={classNames(style.documentsContainer, { 'h-full': categoriesPending || !newCategory && !otherCategories?.length })}>
      <div className={style.digitalReportsContainer}>
        {(reports?.length || hasCapitalAccounts) &&
        <>
          {reports && reports.length > 0 && <ReportsModule />}
          {hasCapitalAccounts && <CapitalAccountsBanner />}
        </>
        }
        {dataRoomFunds?.length > 0 && <DataRoom funds={dataRoomFunds} />}
      </div>
      <DocumentCategories />
    </div>
  );
};
