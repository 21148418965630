import ChildUnticked from '../../../assets/icons/global/empty-checkbox.svg?react';
import Ticked from '../../../assets/icons/global/filled-checkbox.svg?react';
import Unticked from '../../../assets/icons/global/tree-empty-checkbox.svg?react';
import { type TreeNode } from '../TreeMultiSelector';
import { type FC } from 'react';

type TreeMultiSelectorOptionProps = {
  dataSelectionValue: string,
  halfSelected?: boolean,
  isParent: boolean,
  option: TreeNode,
  selected: boolean,
  toggleSelect: (parentNode: TreeNode, event: React.MouseEvent<HTMLElement>) => void,
};

const getCheckBox = (selected: boolean, isParent: boolean, halfSelected: boolean) => {
  if (selected) {
    return (
      <Ticked className='min-w-[1.125rem]' />
    );
  }

  return isParent && halfSelected ? <Unticked className='min-w-[1.125rem]' /> : <ChildUnticked className='min-w-[1.125rem]' />;
};

const TreeMultiSelectorOption: FC<TreeMultiSelectorOptionProps> = ({ option,
  dataSelectionValue,
  selected,
  toggleSelect,
  isParent,
  halfSelected }) => {
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    toggleSelect(option, event);
  };

  return (
    <div
      className='flex max-w-fit flex-1 cursor-pointer items-center gap-[0.35rem] rounded-sm px-[0.625rem] py-[0.5rem]'
      data-closest='option'
      data-test={`${dataSelectionValue}-${option.name}`}
      id={option.id}
      onClick={(event) => onClick(event)}
      title={option.name}
    >
      {
        getCheckBox(selected, isParent, halfSelected as boolean)
      }
      <div className='dt:text-font-3'>
        {option.name}
      </div>
    </div>
  );
};

export { TreeMultiSelectorOption };
