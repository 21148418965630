import { NestedCheckboxList } from 'components/TreeMultiSelector/NestedCheckboxList';
import { type TreeNode } from 'components/TreeMultiSelector/TreeMultiSelector';
import {
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  filterOrganizationByAccounts,
  type OrganizationsStoreState,
} from 'store/slices';
import { type NestedOrganization } from 'types';

const getAllAccounts = (displayedOrganization: NestedOrganization | null) => {
  return displayedOrganization?.funds.flatMap((fund) => fund.accounts) ?? [];
};

const getOptions = (organization: NestedOrganization): TreeNode[] => {
  return organization.funds.map((fund) => {
    return {
      children: fund.accounts.map((acc) => ({
        id: acc.accountId,
        name: acc.accountName,
      })),
      id: fund.fundId,
      name: fund.fundName,
    };
  });
};

export const FundsAndAccountsColumn = () => {
  const dispatch = useDispatch();
  const {
    organizations,
    displayedOrganization } = useSelector<{
    organizations: OrganizationsStoreState,
  }>((state) => state.organizations) as OrganizationsStoreState;

  const currentOrganization = organizations?.find((org) =>
    org.organizationId === displayedOrganization?.organizationId) as NestedOrganization;

  const [
    allSelected,
    setAllSelected,
  ] = useState(currentOrganization?.funds
    .length === displayedOrganization?.funds?.length);

  const checkOption = (selectedAccountIds: string[]) => {
    dispatch(
      filterOrganizationByAccounts(selectedAccountIds),
    );
  };

  const toggleAll = () => {
    const newAllSelected = !allSelected;
    dispatch(filterOrganizationByAccounts(allSelected ? [] :
      getAllAccounts(currentOrganization)
        .map((acc) => acc.accountId) as string[]),
    );

    setAllSelected(newAllSelected);
  };

  return (
    <div className='flex flex-col gap-0.75 dt:min-w-[35.625rem]'>
      <div className='flex items-center justify-between'>
        <span className='px-0.5 text-font-1 font-medium'>Funds and accounts</span>
        <span
          className='cursor-pointer text-font-2 text-black-999 hover:text-main-700'
          data-test={'clear-funds'}
          onClick={toggleAll}
        >
          {`${allSelected ? 'Clear' : 'Select'} all`}
        </span>
      </div>
      <NestedCheckboxList
        childDataSelectionValue='account'
        childStyle='pl-[2rem] rounded-b-sm rounded-t-none bg-rgba-black-100'
        onCheck={checkOption}
        parentDataSelectionValue='fund'
        parentStyle='fund-option flex items-center justify-between gap-[0.75rem] h-[2.75rem] py-[0.75rem] pr-[0.5rem] rounded-sm bg-white-100 w-full'
        selectedData={getOptions(displayedOrganization as NestedOrganization)}
        treeData={getOptions(currentOrganization as NestedOrganization)}
      />
    </div>
  );
};
